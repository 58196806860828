import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as style from "./index.module.scss"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <>
      <div className={style.content}>
        <h1>Welcome</h1>
        <p>
          A website created with Gatsby and React to practice and keep my skills
          as current as possible among the broad spectrum of changing
          technologies.
        </p>
      </div>
    </>
  </Layout>
)

export default IndexPage
